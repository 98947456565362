:root {
  --primary-color: #FF2800; /* Ferrari-Rot */
  --secondary-color: #8B0000; /* Dunkelrot */
  --text-color: #f1f1f1;
  --background-color: #1a1a1a;
  --accent-color: #FFA500; /* Orange */
  --dark-bg-color: #121212;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.App {
  text-align: center;
}

.App-header {
  background-color: var(--dark-bg-color);
  color: var(--text-color);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.App-logo {
  height: 110px;
  margin-right: 10px;
  filter: drop-shadow(0 0 10px rgba(233, 233, 233, 0.5));
  object-fit: cover;
  object-position: center;
}

.App-logo-container {
  display: inline-block;
}

h1, h2, h3 {
  font-weight: 600;
}

h1 {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-top: -10px;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: var(--text-color);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: var(--primary-color);
}

main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

section {
  margin-bottom: 60px;
}

h2 {
  color: var(--primary-color);
  font-size: 2em;
  margin-bottom: 30px;
}

.hero {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: white;
  padding: 80px 20px;
  border-radius: 0 0 50% 50% / 20px;
  margin-bottom: 60px;
}

.hero h2 {
  color: white;
}

.cta-button {
  background-color: var(--accent-color);
  color: white;
  padding: 12px 30px;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: darken(var(--accent-color), 10%);
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.service-item {
  background-color: var(--dark-bg-color);
  color: var(--text-color);
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
  padding: 20px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.service-item i {
  font-size: 2em;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.contact-info p {
  display: flex;
  align-items: center;
}

.contact-info i {
  color: var(--primary-color);
  margin-right: 10px;
}

footer {
  background-color: var(--dark-bg-color);
  color: white;
  padding: 20px;
  text-align: center;
}

.social-media a {
  color: white;
  font-size: 1.5em;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.social-media a:hover {
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
  }

  nav ul li {
    margin: 10px 0;
  }

  .contact-grid {
    grid-template-columns: 1fr;
  }
}

.sticky-promo {
  background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
  color: white;
  padding: 15px;
  font-weight: 600;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.sticky-promo p {
  margin: 0;
  font-size: 1.2em;
  animation: bounce 2s infinite;
}

.sticky-promo .emoji {
  font-size: 1.5em;
  margin: 0 10px;
}

.sticky-promo strong {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.promo-button {
  background-color: var(--text-color);
  color: var(--primary-color);
  border: none;
  padding: 8px 20px;
  border-radius: 25px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.promo-button:hover {
  background-color: var(--accent-color);
  color: var(--text-color);
  transform: scale(1.05);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.details-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.details-button:hover {
  background-color: var(--secondary-color);
  transform: scale(1.05);
}

.service-details {
  margin-top: 20px;
  text-align: left;
}

.service-details h4 {
  color: var(--primary-color);
  margin-bottom: 10px;
}

.service-details ul {
  list-style-type: disc;
  padding-left: 20px;
}

.service-details li {
  margin-bottom: 5px;
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1.5em;
  cursor: pointer;
}

@media (max-width: 768px) {
  .mobile-menu-button {
    display: block;
  }

  nav ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  nav ul.show {
    display: flex;
  }

  nav ul li {
    margin: 10px 0;
  }
}

.contact-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.contact-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.contact-button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-3px);
}

.contact-button i {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .contact-options {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .App-header {
    padding: 10px;
  }

  .header-content {
    flex-direction: column;
    align-items: center;
  }

  .App-logo {
    height: 80px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 1.5em;
  }

  nav ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  nav ul li {
    margin: 5px 10px;
  }
}